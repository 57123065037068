Vue.component('genericFilterComponent',{
    data:function(){
        return {
            alias:{
                Categories:null,
                //Categories:"E1405,A05",
                Items:null,
                //Items:"133301,139803",
                ExpresionFilter:null
            }
        }
    },
    watch: {
        '$store.state.appState':'createFilter',
    },
    computed:{
        aliasCode: function(){
            return this.$route.params.aliasCode;
        }
    },
    mounted:function(){
        if(this.$store.getters.getAppState=='ready')
            this.createFilter();
    },
    methods: {
        createFilter:async function(){
            if(this.$store.getters.getAppState=='ready') {
                let aliasRecord = await this.$store.dispatch('loadAlias',this.aliasCode)
                if(aliasRecord)
                    this.alias = aliasRecord.fields;
                //console.log("alias Code",aliasRecord,this.alias);

                let objectFilter = {};
                objectFilter.name = `alias-filter-${this.aliasCode}`;
                objectFilter.type = 'genericFilter';
                objectFilter.label = this.aliasCode;
                objectFilter.value = this.aliasCode;
                objectFilter.code = this.aliasCode;
                objectFilter.condition = null;
                objectFilter.result = true;
                if(this.alias.Categories)
                    objectFilter.condition = `(['${this.alias.Categories.split(',').join("','")}'].some(r=>x.Classification.split(',').indexOf(r) >= 0))`;
                if(this.alias.Items){
                    if(objectFilter.condition){
                        objectFilter.condition =objectFilter.condition + ` || (['${this.alias.Items.split(',').join("','")}'].indexOf(x.Code)>= 0)`;
                    }
                    else{
                        objectFilter.condition = `(['${this.alias.Items.split(',').join("','")}'].indexOf(x.Code)>= 0)`;
                    }
                }
                if(this.alias.ExpresionFilter){
                    if(objectFilter.condition){
                        objectFilter.condition =objectFilter.condition + ` || (${this.alias.ExpresionFilter})`;
                    }
                    else
                        objectFilter.condition = this.alias.ExpresionFilter;
                }

                if(objectFilter.condition)
                    this.$store.dispatch('addGlobalFilter', objectFilter);
                return objectFilter;
            }
            return none;
        },
        loadCategories: function (root = null, lvl = 0) {
            this.$store.dispatch('loadCategories', root, lvl);
        },
        updateCategory: function (category) {
            this.$store.dispatch('updateCategory', category);
        },
        loadItems: function (category = null) {
            this.$store.dispatch('loadItems', category);
        },
        selectCategory: function (category) {
            if (category) {
                this.loadItems(category.code);
                this.$store.dispatch('updateCategory', category);
            }
            else {
                this.$store.dispatch('updateCategory', {children: []});
                this.loadItems();
            }
        }
    },
    template: `<div class="items-view">
                  <resetPasswordComponent></resetPasswordComponent>
                  <headerSectionComponent></headerSectionComponent>
                  <div class="section white">
                      <div class="container">
                          <template v-if="$store.getters.getAppState!='ready'">               
                                <div class="container center section white" style="min-height: 60%;" >
                                       <h3>{{tr('Page loading please wait')}}</h3>
                                </div>
                          </template>
                          <template v-else>
                                <!--<registerComponent></registerComponent>-->
                                <loginComponent></loginComponent>
                                <div class="row">
                                  <div class="col m3 s12 left sticky-component items-category-tree">
                                      <homeCategorySectionComponent :only_icons_view=true></homeCategorySectionComponent>
                                      <div class="col m10 s12 sticky-component filters-section">
                                        <categorySectionComponent :categories=false></categorySectionComponent>
                                      </div>
                                  </div>                                 
                                  <div class="col m7 s12">
                                      <itemsSectionComponent :items="$store.items" :items_in_car="$store.items_in_car" :cat_history="$store.cat_history"></itemsSectionComponent>
                                  </div>
                                  <div class="col m2 s12 sticky-component right">
                                      <userSectionComponent></userSectionComponent>
                                      <carComponent></carComponent>
                                  </div>
                              </div>
                          </template>
                          
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
                </div>`
})